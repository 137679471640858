import styled from 'styled-components';
// import MuiCardMedia from '@material-ui/core/CardMedia';
import {
  CardContent as MuiCardContent,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Popover,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MuiAvatar from '@atoms/Avatar';
import Typography from '@atoms/Typography';
import Link from '@atoms/Link';
import { deviceSizes } from '@app/config/devices';
import Button from '@app/components/atoms/Button';
// export const CardMedia = styled(MuiCardMedia)`
//   height: 15.625rem;
//   && {
//     border-bottom: 4px solid red;
//   }
// `;

export const CardContent = styled(MuiCardContent)`
  display: flex;
  position: relative;

  border-top: 3px solid ${({ theme }) => theme.primary.main};
`;

export const StyledLink = styled(Link)`
  && {
    color: white;
    text-decoration: none;
    & :hover {
      text-decoration: underline;
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* padding-left: 16px; */
  width: 100%;
`;

export const Card = styled(MuiCard)`
  && {
    /* border-radius: 13px; */
    position: relative;
    border-radius: 20px;
    transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    // height: 453px;
    height: 100%;
    & :hover {
      // transform: translate(0, -15px);
      .additionalInfo {
        top: 0px;
      }
    }
    @media (max-width: ${deviceSizes.sm}) {
      margin-right: 16px;
    }
  }
`;

export const CardHeader = styled(MuiCardHeader)`
  && {
    padding-left: 0;
    padding-right: 0;
    .MuiCardHeader-action {
      align-self: flex-end;
    }
  }
`;

export const CardLabel = styled(Typography)`
  display: flex;
  && {
    color: ${({ theme }) => theme && theme.secondaryColor[800]};
    /* color: #5C6BC0; */
    // margin-bottom: 10px;
  }
  &:hover {
    cursor: pointer;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 60px;
`;

export const CardSublabel = styled(Typography)`
  // overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  height: 14px;
  color: ${({ theme }) => theme && theme.secondaryColor[700]};
`;

export const MetaItem = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  svg {
    margin-right: 1rem;
  }
  height: 24px;
`;

export const MetaItemDesc = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  height: 34px;
`;

export const OrganisationAvatar = styled(MuiAvatar)`
  && {
    position: absolute;
    right: 18px;
    top: -45px;
    background-color: white;
    border: 3px solid ${({ theme }) => theme.primary.main};
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const OpportunityDescription = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
`;

export const useClasses = makeStyles(() => ({
  image: {
    width: '100%',
    height: '136px',
    // '& > div': {
    // borderBottom: `4px solid ${theme.palette.primary.main}`,
    // },
  },
  mediaContainer: {
    position: 'relative',
    overflow: 'hidden',
    // '&:hover': {
    //   '& .additionalInfo': {
    //     top: '0px',
    //   },
    // },
  },
  additionalInfo: {
    position: 'absolute',
    top: '227px',
    height: '100%',
    background: 'rgba(0,0,0,0.65)',
    width: '100%',
    color: 'white',
    padding: '20px',
    transition: 'all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    display: 'flex',
    flexDirection: 'row',
  },
  details: {
    display: 'flex',
    flexBasis: '80%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  stats: {
    display: 'flex',
    flexBasis: '20%',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'flex-end',
  },
}));

export const ShareButton = styled(Button)`
  && {
    // background-color: white;
    // margin-top: 10px;
    border: 0;
    padding: 0;
    // color: initial;

    &:hover {
      border: 0;
      background-color: initial;
      border-color: initial;
      color: ${({ theme }) => theme.red[800]};
    }
  }
`;

export const StyledPopover = styled(Popover)`
  && {
    .MuiPopover-paper {
      box-shadow: none;
      // background-color: inherit;
    }
    svg{
      margin: 0 10px;
    }
  }
`;

export const CopyCliboard = styled.div`
  display: flex;
  align-items: center;
  svg{
    cursor: pointer;
  }
`